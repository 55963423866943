import React, { useState } from "react";

const SingleSelectInput = (props) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const val = event.target.value;
    props.onChange(props.API_name, val);
  };

  return (
    <select
      value={selectedOption}
      onChange={handleOptionChange}
      className="text-input"
      style={{
        width: "20rem",
        height: "3rem",
        textAlign: "center",
        fontSize: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <option value="">{props.name}</option>
      {props.options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default SingleSelectInput;
