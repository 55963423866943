import React from "react";
import axios from "axios";
import binImage from "./bin_484662.png";
import "./ImageButton.css"; // Import the CSS file for styling

const ImageButton = (props) => {
  return (
    <button className="image-button" onClick={props.onClick}>
      <img src={binImage} alt="bin" style={{ width: "20px", height: "20px" }} />
    </button>
  );
};

export default ImageButton;
