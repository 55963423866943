import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BigLogo from "./BigLogo";
import axios from "axios";
import XTRFEmail from "./XTRFEmail";
import Info from "./UI/Info";
import DOMAIN from "../domain";

const VerificationComponent = () => {
  const [shopHostname, setShopHostname] = useState("");
  const [apiAccessKey, setApiAccessKey] = useState("");
  const [verificationResult, setVerificationResult] = useState("");
  const [verified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Shopify Connector - Login";
  }, []);

  useEffect(() => {
    const isAuthenticatedShopify = JSON.parse(
      localStorage.getItem("shopify-authenticated")
    );
    const isAuthenticatedXTRF = JSON.parse(
      localStorage.getItem("XTRFAuthenticated")
    );
    if (isAuthenticatedShopify === true && isAuthenticatedXTRF === true) {
      navigate("/client");
    }
  }, [navigate]);

  const handleVerification = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${DOMAIN}/verify-shopify-credentials`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shopHostname, apiAccessKey }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("shopHostname", shopHostname);
        localStorage.setItem("apiAccessKey", apiAccessKey);
        localStorage.setItem("shopify-authenticated", true);
        setVerificationResult("Invalid shop credentials.");
        XTRFVerification();
      } else {
        setVerificationResult("Invalid shop credentials.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error occurred during verification:", error);
      setVerificationResult("An error occurred during verification.");
      setIsLoading(false);
    }
  };

  const XTRFVerification = () => {
    axios
      .post(`${DOMAIN}/db/emails/authorize`, {
        hostname: localStorage.getItem("shopHostname"),
      })
      .then((res) => {
        if (res.data.exists) {
          localStorage.setItem("XTRFemail", res.data.email.email);
          navigate("/client");
        } else if (!res.data.exists) {
          setVerified(true);
          localStorage.setItem("XTRFAuthenticated", false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <div className="login-container">
      <BigLogo />
      {verified ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              border: "2px #006ff7 solid",
              borderRadius: "18px",
              padding: "5px 20px 5px 20px",
              alignSelf: "center",
              textAlign: "center",
              margin: "0 auto",
              fontSize: "27px",
            }}
          >
            <p style={{ color: "#006ff7" }}>
              Congratulations! <br></br> Your Shopify store has been <br></br>{" "}
              found and account verified.
            </p>
          </div>
          <div className="login-section">
            <XTRFEmail />
          </div>
        </div>
      ) : (
        <div className="login-section">
          <>
            <div>
              <input
                type="text"
                placeholder="Shop Hostname"
                value={shopHostname}
                onChange={(e) => setShopHostname(e.target.value)}
                className="text-input"
                style={{
                  width: "20rem",
                  height: "3rem",
                  textAlign: "center",
                  fontSize: "20px",
                  borderRadius: "18px",
                  border: "none",
                }}
              />

              <Info info="The web address where your store is hosted and can be accessed online. It typically follows the format “store-name.myshopify.com.” " />
            </div>
            <div>
              <input
                placeholder="API Access Token"
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                value={apiAccessKey}
                onChange={(e) => setApiAccessKey(e.target.value)}
                className="text-input"
                style={{
                  width: "20rem",
                  height: "3rem",
                  textAlign: "center",
                  fontSize: "20px",
                  borderRadius: "18px",
                  border: "none",
                }}
              />
              <Info info="A Shopify API access token is a unique code that grants authorized access to a Shopify store's data and functionality through the Shopify API." />
            </div>
            <button
              className="login-button btn shopify-submit"
              onClick={handleVerification}
              style={{
                width: "20rem",
                height: "3rem",
                textAlign: "center",
                fontSize: "20px",
                alignSelf: "flex-start",
                borderRadius: "18px",
              }}
            >
              Verify Credentials
            </button>
            {isLoading ? (
              <div className="spinner">Loading...</div>
            ) : (
              <p className="invalid-text">{verificationResult}</p>
            )}
          </>
        </div>
      )}

      <div className="filler" style={{ height: "500px" }}></div>
    </div>
  );
};

export default VerificationComponent;
