export const specialisations = {
  Veterinary: 191,
  Transcreation: 210,
  Technology: 14,
  Science: 13,
  Relocation: 215,
  Recruitment: 217,
  "Politics & Government": 162,
  "Online Training": 214,
  "Multimedia & Media": 142,
  "Mining & Minerals / Gems": 141,
  "Military & Defence": 140,
  "Medical (general)": 131,
  "Medical: Pharmaceuticals": 136,
  "Medical: Instruments": 135,
  "Medical: Health Care": 134,
  "Medical: Dentistry": 133,
  "Medical: Cardiology": 132,
  "Maritime - Ships": 125,
  Manufacturing: 124,
  Literature: 10,
  Legal: 113,
  Journalism: 110,
  "IT - Applications & Systems": 208,
  "Human Sciences": 6,
  "Human Resources": 101,
  History: 99,
  "Health & safety": 198,
  General: 5,
  "Food and drink": 212,
  "Face-to-Face training": 213,
  Environment: 4,
  Engineering: 23,
  "Energy Nuclear Oil & Gas": 66,
  Construction: 19,
  "Communications & Telecommunications": 53,
  "Business & Commerce": 207,
  "Banking Finance & Mathematics": 41,
  "Automotive & Transport": 206,
  "Astronomy & Space": 37,
  Agriculture: 31,
  "Advertising & Marketing": 29,
  "Accent Reduction": 216,
};
