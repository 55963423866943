import { useMsal } from "@azure/msal-react";

const LoginPage = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <div className="login-button btn" onClick={initializeSignIn}>
      Sign in with Azure
    </div>
  );
};

export default LoginPage;
