import React, { useState } from "react";

const DatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    const val = event.target.value;
    props.onChange(props.API_name, val);
  };

  return (
    <div className="date">
      <h3>{props.name}</h3>
      <input
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        style={{
          width: "20rem",
          height: "3rem",
          textAlign: "center",
          fontSize: "20px",
          borderRadius: "18px",
          border: "none",
        }}
      />
      {selectedDate && <p>Selected Date: {selectedDate}</p>}
    </div>
  );
};

export default DatePicker;
