import React from "react";
import { Link } from "react-router-dom";
import BigLogo from "./BigLogo";
import Banner from "./Banner";

const Main = () => {
  const blue = {
    color: "#0000ee",
  };
  return (
    <div className="super-container">
      <Banner />
      <div className="landing-section">
        <div className="landing-left">
          <h1 className="landing-text" style={{ color: "#34414a" }}>
            Your Shopify Store
          </h1>
          <h1 className="landing-text" style={blue}>
            Translated.
          </h1>
          <h1 className="landing-text" style={blue}>
            Localised.
          </h1>
          <h1 className="landing-text" style={blue}>
            Globalised.
          </h1>
          <Link to="/authenticate" className="landing-button" id="shopify">
            Login with Shopify
          </Link>
        </div>
        <div className="landing-right" style={{ paddingTop: "0" }}>
          <img
            src=""
            alt="3D Smartphone displaying an ecommerce store and cart"
            srcSet="../landing.png"
            // style={{ alignSelf: "flex-end" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
