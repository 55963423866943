import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Main";
import ClientView from "./ClientView";
import VerificationComponent from "./ShopifyLogin";
import Protected from "./Protected";
import WolfestoneMain from "./WolfestoneMain";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("apiAccessKey");
    isAuthenticated ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, []);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/wolfestone" element={<WolfestoneMain />} />
          <Route
            path="/client"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <ClientView />
              </Protected>
            }
          />
          <Route path="/authenticate" element={<VerificationComponent />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
