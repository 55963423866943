import React, { useState } from "react";

function Info(props) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: "pointer",
          marginLeft: "5px",
          fontSize: "20px",
          fontWeight: "bolder",
          color: "#405cf5",
        }}
      >
        ⓘ
      </span>
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            zIndex: 999,
            backgroundColor: "white",
            padding: "10px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
            minWidth: "200px",
            width: "min-content",
            whiteSpace: "normal",
          }}
        >
          {props.info}
        </div>
      )}
    </div>
  );
}

export default Info;
