import React, { useState } from "react";

const TextInput = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const val = event.target.value;
    setValue(event.target.value);
    props.onChange(props.API_name, val);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder={props.placeholder}
      className="text-input"
      style={{
        width: "20rem",
        height: "3rem",
        textAlign: "center",
        fontSize: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    />
  );
};

export default TextInput;
