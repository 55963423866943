export const languages = {
  Abkhazian: 1,
  Afar: 2,
  "Afghan Persian": 154,
  Afrikaans: 3,
  Akan: 168,
  Albanian: 4,
  "Amazigh (Moroccan Berber)": 321,
  "American Sign Language": 330,
  Amharic: 5,
  Arabic: 6,
  "Arabic (Algeria)": 176,
  "Arabic (Bahrain)": 175,
  "Arabic (Egypt)": 177,
  "Arabic (Iraq)": 178,
  "Arabic (Jordan)": 179,
  "Arabic (Kuwait)": 180,
  "Arabic (Lebanon)": 181,
  "Arabic (Lybia)": 182,
  "Arabic (Morocco)": 183,
  "Arabic (Oman)": 184,
  "Arabic (Qatar)": 185,
  "Arabic (Saudi Arabia)": 186,
  "Arabic (Syria)": 187,
  "Arabic (Tunisia)": 188,
  "Arabic (U.A.E.)": 189,
  "Arabic (Yemen)": 190,
  Aramaic: 172,
  Armenian: 7,
  Assamese: 8,
  Assyrian: 158,
  Aymara: 9,
  Azerbaijani: 10,
  "Azeri (Cyrillic)": 191,
  "Azeri (Latin)": 192,
  Bangladeshi: 160,
  Bashkir: 11,
  Basque: 12,
  Belarusian: 21,
  "Belgian Dutch": 161,
  Bengali: 13,
  Bhutani: 14,
  Bihari: 15,
  Bislama: 16,
  Bosnian: 17,
  "Bosnian (Cyrillic)": 317,
  Braille: 171,
  Breton: 18,
  "British Sign Language": 265,
  Bulgarian: 19,
  Burmese: 20,
  Cambodian: 22,
  "Cantonese (Training)": 312,
  Catalan: 23,
  "Cebuano (Philippines)": 264,
  "Chatgaya [Chittagonian]": 323,
  Chewa: 257,
  "Chinese (Hong Kong S.A.R.)": 193,
  "Chinese (Macao S.A.R.)": 194,
  "Chinese (PRC)": 195,
  "Chinese (Singapore)": 196,
  "Chinese (Taiwan)": 197,
  "Chinese Mandarin (Training)": 311,
  "Chinese simplified": 24,
  "Chinese traditional": 25,
  Cornish: 331,
  Corsican: 26,
  Creole: 157,
  Croatian: 27,
  Czech: 28,
  Danish: 29,
  Dari: 272,
  Dhivehi: 316,
  Dutch: 30,
  Edo: 143,
  "English (Australia)": 198,
  "English (Belize)": 199,
  "English (Canada)": 200,
  "English (Caribbean)": 201,
  "English (Czech)": 340,
  "English (Danish)": 343,
  "English (Hungarian)": 344,
  "English (India)": 351,
  "English (Ireland)": 202,
  "English (Israeli)": 336,
  "English (Jamaica)": 203,
  "English (Korean)": 334,
  "English (New Zealand)": 204,
  "English (Philippines)": 205,
  "English (Polish)": 335,
  "English (Scottish)": 341,
  "English (Singapore)": 350,
  "English (South Africa)": 206,
  "English (Trinidad and Tobago)": 207,
  "English (Zimbabwe)": 209,
  "English UK": 31,
  "English US": 153,
  "English US (North American)": 345,
  Esperanto: 32,
  Estonian: 33,
  Fante: 255,
  Faroese: 34,
  Farsi: 144,
  Fiji: 35,
  Finnish: 36,
  Flemish: 146,
  "French (Belgium)": 162,
  "French (Canadian)": 159,
  "French (France)": 37,
  "French (Luxembourg)": 211,
  "French (Monaco)": 212,
  "French (Swiss)": 150,
  "French Moroccan": 152,
  Frisian: 38,
  "GA [G2]": 254,
  "Gaelic (Scotland)": 213,
  Galician: 39,
  Georgian: 40,
  "German (Austria)": 147,
  "German (Germany)": 41,
  "German (Liechtenstein)": 215,
  "German (Luxembourg)": 216,
  "German (Swiss)": 151,
  Ghananian: 155,
  Greek: 42,
  Greenlandic: 43,
  Guarani: 44,
  "Guinea-Bissau Creole": 319,
  Gujarati: 45,
  "Haitian Creole": 256,
  Hausa: 46,
  Hebrew: 47,
  "Hebrew (Traditional)": 174,
  Hindi: 48,
  Hindko: 342,
  Hmong: 164,
  Hungarian: 49,
  "INVALID Afghan Persian [AFP INVALI]": 141,
  "Icelandic [IS]": 50,
  "Igbo [ig]": 142,
  "Ilocano [ilo]": 268,
  "Indonesian [ID]": 51,
  "Interlingua [IA]": 52,
  "Interlingue [IE]": 53,
  "Inuktitut [IU]": 54,
  "Inupiak [IK]": 55,
  "Irish [GA]": 56,
  "Ishan/Esan [ISH]": 253,
  "Italian (Switzerland) [it-CH]": 218,
  "Italian [it-IT]": 57,
  "Jamaican Patois [JAM]": 338,
  "Japanese [JA]": 58,
  "Javanese [jv-ID]": 59,
  "Kannada [KN]": 60,
  "Karen [KW]": 165,
  "Karenni [ka2]": 167,
  "Kashmiri [KS]": 61,
  "Kayan [kys]": 166,
  "Kazakh [KK]": 62,
  "Kermanshahi [SDH]": 170,
  "Kikuyu [ki]": 261,
  "Kinyarwanda [RW]": 63,
  "Kirghiz [KY]": 64,
  "Kirundi [RN]": 65,
  "Konkani [kok-IN]": 163,
  "Korean [KO]": 66,
  "Krio [kri]": 252,
  "Kurdish (Kurmanji) [Kurdish (Kurmanji)]": 314,
  "Kurdish (Sorani) [Kurdish (Sorani)]": 315,
  "Kurdish [KU (Turkey)]": 67,
  "Kurmanji [KMR]": 169,
  "Laothian [lo-LA]": 68,
  "Latin [LA]": 69,
  "Latvian [LV]": 70,
  "Lingala [ln-CG]": 71,
  "Lithuanian [LT]": 72,
  "Live Caps/VO - English (Scottish) [en-SH]": 284,
  "Luganda [lg]": 308,
  "Luxembourgish [lb_LU]": 219,
  "Macedonian [MK]": 73,
  "Malagasy [MG]": 74,
  "Malay [MS]": 75,
  "Malayalam [ML]": 76,
  "Maltese [MT]": 77,
  "Mandinka [mnk-MNK]": 271,
  "Manx [[Manx]]": 332,
  "Maori [MI]": 78,
  "Marathi [MR]": 79,
  "Mirpuri [phr]": 309,
  "Moldavian [MO]": 80,
  "Mongolian [MN]": 81,
  "Montenegrin [sla-ME]": 173,
  "Nauru [na-NR]": 82,
  "Navajo [[NV]]": 353,
  "Nepali [ne-NP]": 83,
  "Nigerian Pidgin [PCM]": 267,
  "Nkole [Nyn]": 307,
  "Noon [[SNF]]": 352,
  "Norwegian (Bokmål) [nb-NO]": 220,
  "Norwegian (Nynorsk) [nn-NO]": 221,
  "Norwegian [no-NO]": 84,
  "Occitan [oc-FR]": 85,
  "Ojibwe [[OJ]]": 355,
  "Oriya [or-IN]": 86,
  "Oromo [om-ET]": 87,
  "Papiamento [PAP]": 333,
  "Pashto [PS]": 88,
  "Pennsylvania Dutch [[NL_PE]]": 354,
  "Persian [fa-IR]": 89,
  "Polish [PL]": 90,
  "Portuguese (DONTUSE - Portugal) [por-PT]": 222,
  "Portuguese Brazilian [PT-BR]": 148,
  "Portuguese [pt-PT]": 91,
  "Punjabi - India [pa_IN]": 327,
  "Punjabi - Pakistan [pa_PK]": 328,
  "Punjabi [PA]": 92,
  "Quechua [QU]": 93,
  "Rhaeto-Romance [RM]": 94,
  "Romanes [Romanes]": 325,
  "Romanian [RO]": 95,
  "Russian [RU]": 96,
  "Sambal [xsb]": 266,
  "Samoan [sm-WS]": 97,
  "Sangho [SG]": 98,
  "Sanskrit [SA]": 99,
  "ScandiMix [DA/NO/SV]": 320,
  "Scots (Gaelic) [Gaelic]": 100,
  "Scottish Gaelic (Scotland) [gd_GB]": 310,
  "Sepedi [nso]": 313,
  "Serbian (Cyrillic) [scc]": 223,
  "Serbian (Latin) [scr]": 224,
  "Serbian [SR]": 101,
  "Sesotho [ST]": 102,
  "Setswana [TN]": 103,
  "Shona [SN]": 104,
  "Sindhi [sd-PK]": 105,
  "Sinhalese [si-LK]": 106,
  "Siswati [SS]": 107,
  "Slovak [SK]": 108,
  "Slovenian [SL]": 109,
  "Somali (Djibouti) [som-DJ]": 225,
  "Somali (Ethiopia) [som-ET]": 226,
  "Somali (Kenya) [som-KE]": 227,
  "Somali (Somalia) [som-SO]": 228,
  "Somali [SO]": 110,
  "Soninke [SNK]": 251,
  "South Korean [sKO]": 156,
  "Spanish (Argentina) [es-AR]": 229,
  "Spanish (Bolivia) [es-BO]": 230,
  "Spanish (Chile) [es-CL]": 231,
  "Spanish (Colombia) [es-CO]": 232,
  "Spanish (Costa Rica) [es-CR]": 233,
  "Spanish (Dominican Republic) [es-DO]": 234,
  "Spanish (Ecuador) [es-EC]": 235,
  "Spanish (El Salvador) [es-SV]": 236,
  "Spanish (Guatemala) [es-GT]": 237,
  "Spanish (Honduras) [es-HN]": 238,
  "Spanish (Latin America) [ES-419]": 262,
  "Spanish (Mexico) [es-MX]": 239,
  "Spanish (Nicaragua) [es-NI]": 240,
  "Spanish (Panama) [es-PA]": 241,
  "Spanish (Paraguay) [es-PY]": 242,
  "Spanish (Peru) [es-PE]": 243,
  "Spanish (Puerto Rico) [es-PR]": 244,
  "Spanish (South America) [es-SA]": 149,
  "Spanish (Spain) -DO NOT USE [spa-ES]": 245,
  "Spanish (Spain) [es-ES]": 111,
  "Spanish (USA) [es_US]": 339,
  "Spanish (Uruguay) [es-UY]": 246,
  "Spanish (Venezuela) [es-VE]": 247,
  "Sundanese [SU]": 112,
  "Swahili [sw-KE]": 113,
  "Swedish (Finland) [swe-FI]": 248,
  "Swedish (Sweden) [swe-SE]": 249,
  "Swedish [SV]": 114,
  "Sylheti [Silôţi]": 145,
  "TEST LANGUAGE 1 [TL1]": 259,
  "TEST LANGUAGE 2 [TL2]": 260,
  "Tagalog [TL]": 115,
  "Tajik [tg-TJ]": 116,
  "Tamil [TA]": 117,
  "Tatar [TT]": 118,
  "Telugu [TE]": 119,
  "Tetum [TI] [TET]": 258,
  "Thai [TH]": 120,
  "Tibetan [BO]": 121,
  "Tigrinya [TI]": 122,
  "Tok Pisin [TPI]": 270,
  "Tonga [TO]": 123,
  "Tsonga [ts-ZA]": 124,
  Turkish: 125,
  Turkmen: 126,
  "Twi [TW]": 127,
  "Uighur [ug-CN]": 128,
  Ukrainian: 129,
  Umbundu: 318,
  "Urdu - Pakistan": 326,
  "Urdu [UR-IN]": 130,
  "Uyghur [UG]": 269,
  "Uzbek [UZ]": 131,
  "Uzbek [UZ] Cyrillic": 322,
  "Vietnamese [VI]": 132,
  "Volapuk [VO]": 133,
  "Welsh [CY]": 134,
  "Wolof [WO]": 135,
  "Xhosa [XH]": 136,
  "Yiddish [YI]": 137,
  "Yoruba [yo-NG]": 138,
  "Zhuang [ZA]": 139,
  "Zulu [ZU]": 140,
};
