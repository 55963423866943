import React, { useState, useEffect } from "react";
import TextInput from "./inputs/TextInput";
import SingleSelectInput from "./inputs/SingleSelectInput";
import MultipleChoiceInput from "./inputs/MultipleChoiceInput";
import DatePicker from "./inputs/DatePicker";
import { languages } from "../languages";
import { specialisations } from "../specialisations";
import { services } from "../services";
import { dateToUnixTime } from "../toUnix";
import axios from "axios";
import Button from "./Button";
import BigLogo from "./BigLogo";
import FileSelector from "./inputs/FileSelector";
import Info from "./UI/Info";
import DOMAIN from "../domain";

const CreateQuote = (props) => {
  const [req, setReq] = React.useState({
    hostname: localStorage.getItem("shopHostname"),
    login: localStorage.getItem("XTRFemail"),
    token: "PFgbbzTP0WvHZa8W0qm3RBzGzf",
    name: "",
    customerProjectNumber: "",
    serviceId: 0,
    sourceLanguageId: 0,
    targetLanguageIds: [],
    specializationId: 0,
    deliveryDate: {
      time: 0,
    },
    files: [],
  });

  const [fileNames, setFileNames] = React.useState([]);
  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    document.title = "Shopify Connector - Quote";
  }, []);

  function makeReq(input, inputVal) {
    let newVal = inputVal;

    switch (input) {
      case "targetLanguageIds":
        newVal = [];
        newVal.push(languages[inputVal]);
        break;
      case "sourceLanguageId":
        newVal = languages[newVal];
        break;
      case "serviceId":
        newVal = services[newVal];
        break;
      case "specializationId":
        newVal = specialisations[newVal];
        break;
      case "deliveryDate":
        newVal = { time: dateToUnixTime(newVal) };
        break;
      case "tokenShopify":
        return null;
      case "urlShopify":
        return null;
      default:
        break;
    }

    setReq((prevVal) => {
      return {
        ...prevVal,
        [input]: newVal,
      };
    });
  }

  function sendQuote(filesVal, cookie) {
    const updatedReq = {
      ...req,
      cookie: cookie,
    };
    axios
      .post(`${DOMAIN}/xtrf/createQuote/`, updatedReq)
      .then((res) => {
        props.save({
          ...req,
          quoteId: res.data.id,
          idNumber: res.data.idNumber,
          done: false,
        });
        axios
          .post(`${DOMAIN}/xtrf/tasks`, {
            quoteResponse: res,
            files: filesVal,
            hostname: localStorage.getItem("shopHostname"),
          })
          .then((res) => console.log(res.data))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  function sendShopifyRequests() {
    const filesData = {};
    const requests = fileNames.map((fileName) => {
      return axios
        .post(`${DOMAIN}/shopify/${fileName}`, {
          hostname: localStorage.getItem("shopHostname"),
          accessToken: localStorage.getItem("apiAccessKey"),
        })
        .then((res) => {
          filesData[fileName] = res.data;
        });
    });
    Promise.all(requests)
      .then(() => {
        axios
          .post(`${DOMAIN}/xtrf/uploadFiles/handleFiles`, {
            ...filesData,
            hostname: localStorage.getItem("shopHostname"),
          })
          .then(() => {
            props.toggleView();
            axios
              .post(`${DOMAIN}/xtrf/uploadFiles`, {
                login: localStorage.getItem("XTRFemail"),
                token: "PFgbbzTP0WvHZa8W0qm3RBzGzf",
                hostname: localStorage.getItem("shopHostname"),
              })
              .then((res) => {
                setFiles(res.data.files);
                setReq((prevVal) => {
                  return {
                    ...prevVal,
                    files: res.data.files,
                  };
                });
                sendQuote(res.data.files, res.data.cookie);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  console.log(req);

  return (
    <div className="client-container">
      <BigLogo />
      <div className="client-section">
        <div>
          <button
            className="login-button btn shopify-submit"
            onClick={props.toggleView}
            text="Home"
            style={{
              width: "20rem",
              height: "3rem",
              textAlign: "center",
              fontSize: "20px",
              borderRadius: "18px",
              border: "none",
              alignSelf: "center",
            }}
          >
            Home
          </button>
          <div
            style={{ marginLeft: "245px", width: "100px", padding: "0" }}
          ></div>
        </div>

        <div>
          <TextInput
            placeholder="Project Name"
            API_name="name"
            onChange={makeReq}
          />
          <div
            style={{ marginLeft: "245px", width: "100px", padding: "0" }}
          ></div>
        </div>
        <div>
          <TextInput
            placeholder="Project Number"
            API_name="customerProjectNumber"
            onChange={makeReq}
          />
          <Info info="For your internal records." />
        </div>
        <div>
          <SingleSelectInput
            name="Source Language"
            options={Object.keys(languages)}
            API_name="sourceLanguageId"
            onChange={makeReq}
          />
          <Info info="The language your store is currently in." />
        </div>
        <div style={{ textAlign: "center" }}>
          <p>One target language per quote request.</p>
          <SingleSelectInput
            name="Target language"
            options={Object.keys(languages)}
            API_name="targetLanguageIds"
            onChange={makeReq}
          />
          <Info info="The language you would like your store to be translated into." />
        </div>
        <div>
          <SingleSelectInput
            name="Specialisation"
            options={Object.keys(specialisations)}
            API_name="specializationId"
            onChange={makeReq}
          />
          <div style={{ marginLeft: "245px", width: "100px" }}></div>
        </div>
        <div>
          <SingleSelectInput
            name="Service"
            options={Object.keys(services)}
            API_name="serviceId"
            onChange={makeReq}
          />
          <Info info="For more information on our service levels, visit our website." />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            name="Deadline"
            API_name="deliveryDate"
            onChange={makeReq}
          />
          <div style={{ alignSelf: "center" }}>
            <Info info="When would you like the project completed by." />
          </div>
        </div>
        <FileSelector
          name="Files for translation"
          options={["Articles", "Blogs", "Products", "Pages", "Collections"]}
          API_name="files"
          onChange={(file, val) => {
            if (val.length > 0) {
              setFileNames(val);
            }
          }}
        />
        <button
          onClick={() => {
            sendShopifyRequests();
          }}
          text="Submit"
          style={{
            width: "20rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "none",
          }}
          className="login-button btn shopify-submit"
        >
          Submit
        </button>
      </div>
      <div className="filler"></div>
    </div>
  );
};

export default CreateQuote;
