import React from "react";
import axios from "axios";
import DOMAIN from "../../domain";

const Project = (props) => {
  const [done, setDone] = React.useState(false);
  const [inDB, setInDB] = React.useState(false);

  React.useEffect(() => {
    checkInDB();
  }, []);
  function markQuote() {
    axios.patch(`${DOMAIN}/database/quotes/done`, {
      idNumber: props.project.instructions.internal,
    });
  }

  async function checkInDB() {
    try {
      const project = await axios.get(
        `${DOMAIN}/xtrf/projects/${props.project.projectId}`
      );
      setInDB(project.data.exists);
      forceUpdate(); // Trigger a re-render after database check
    } catch (err) {
      console.error(err);
    }
  }

  async function sendId(id) {
    const people = await axios.get(`${DOMAIN}/database/emails`);
    let credentials = {};
    people.data.forEach((person) => {
      if (person.email === props.project.email) {
        credentials = person;
      }
    });
    await axios.post(`${DOMAIN}/xtrf/projects/getTasks`, {
      id: id,
      login: credentials.email,
      hostname: credentials.hostname,
      apiKey: credentials.apiKey,
    });
    await axios.post(`${DOMAIN}/xtrf/projects/add`, {
      name: props.project.name,
      projectId: props.project.projectId,
    });
    window.location.reload();
  }

  function forceUpdate() {
    setInDB(!inDB);
  }

  if (done) {
    return (
      <div
        className="project"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Are you sure the project is finished?</p>
        <button
          className="tick-button"
          onClick={() => {
            sendId(props.project.projectId);
            markQuote();
          }}
        >
          &#10003;
        </button>
        <button
          className="cross-button"
          onClick={() => {
            setDone(false);
          }}
        >
          &#10005;
        </button>
      </div>
    );
  }

  if (inDB) {
    return (
      <div className="project" style={{ backgroundColor: "#a4f89c" }}>
        <div>
          <strong>Finished</strong>
        </div>
        <div>
          <strong>Project ID</strong>
          <p>{props.project.idNumber}</p>
        </div>
        <div>
          <strong>Name</strong>
          <p>{props.project.name}</p>
        </div>
        <div>
          <strong>Customer</strong>
          <p>{props.project.email}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="project"
      style={{ display: "flex", justifyContent: "space-around" }}
    >
      <div>
        <strong>Project ID</strong>
        <p>{props.project.idNumber}</p>
      </div>
      <div>
        <strong>Name</strong>
        <p>{props.project.name}</p>
      </div>
      <div>
        <strong>Customer</strong>
        <p>{props.project.email}</p>
      </div>
      <button
        className="button-1"
        onClick={() => {
          setDone(true);
        }}
        style={{ marginLeft: "auto" }}
      >
        Done
      </button>
    </div>
  );
};

export default Project;
