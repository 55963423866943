import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import Project from "./UI/Project";
import BigLogo from "./BigLogo";

const Wolfestone = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup();
  };

  useEffect(() => {
    // Fetch projects with pagination
    const fetchData = async () => {
      const url =
        "https://wolfestone-translation.com/home-api/browser/?viewId=28521&q.customer.categories=anyOf(316)";
      const headers = {
        accept: "application/vnd.xtrf-v1+json;charset=UTF-8",
        "X-AUTH-ACCESS-TOKEN": "PFgbbzTP0WvHZa8W0qm3RBzGzf",
      };

      try {
        let allProjects = [];
        let currentPage = 1;
        let totalPages = 1;

        while (currentPage <= totalPages) {
          const response = await axios.get(url + `&page=${currentPage}`, {
            headers,
          });

          const { rows, header } = response.data;
          allProjects.push(...Object.values(rows));

          // Update totalPages based on the pagination details in the response
          totalPages = header.pagination.pagesCount;
          currentPage++;
        }

        // Now allProjects contains data from all pages
        const ids = allProjects.map((row) => row.id);
        const projectRequests = ids.map((id) =>
          axios.get(
            `https://wolfestone-translation.com/home-api/projects/${id}`,
            {
              headers,
            }
          )
        );

        Promise.all(projectRequests)
          .then((responses) => {
            const projectData = responses.map((response) => response.data);
            setProjects(projectData);
          })
          .catch((error) => {
            console.error("Error fetching project details:", error);
          });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const projectEmails = await Promise.all(
          projects.map((project) =>
            axios.get(
              `https://wolfestone-translation.com/home-api/customers/persons/${project.contacts.primaryId}`,
              {
                headers: {
                  accept: "application/vnd.xtrf-v1+json;charset=UTF-8",
                  "X-AUTH-ACCESS-TOKEN": "PFgbbzTP0WvHZa8W0qm3RBzGzf",
                },
              }
            )
          )
        );

        const updatedProjects = projects.map((project, index) => ({
          ...project,
          email: projectEmails[index].data.contact.emails.primary,
        }));

        setProjects(updatedProjects);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (projects.length > 0) {
      fetchEmails();
    }
  }, [projects]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BigLogo />
      </div>
      <div className="wolfestone-container body">
        <button className="button-1" onClick={handleLogout}>
          Logout
        </button>
        {projects.map((project, index) => (
          <Project key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Wolfestone;
