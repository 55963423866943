import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DOMAIN from "../domain";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function XTRFEmail() {
  const [xtrfEmail, setXTRFEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Shopify Connector - Login";
  }, []);

  const debouncedXtrfEmail = useDebounce(xtrfEmail, 500); // Debounce the email value

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);

    const url = `${DOMAIN}/xtrf/createQuote/clientToken`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const data = new URLSearchParams();
    data.append("login", xtrfEmail);
    data.append("token", "PFgbbzTP0WvHZa8W0qm3RBzGzf");

    try {
      const response = await axios.post(url, data, { headers });

      if (response.data.token) {
        localStorage.setItem("XTRFemail", xtrfEmail);
        localStorage.setItem("XTRFAuthenticated", true);

        await axios.post(`${DOMAIN}/db/emails/add`, {
          hostname: localStorage.getItem("shopHostname"),
          email: localStorage.getItem("XTRFemail"),
          apiKey: localStorage.getItem("apiAccessKey"),
        });

        navigate("/client");
      } else {
        setInvalid(true);
      }

      setSubmitting(false);
    } catch (error) {
      setInvalid(true);
      setSubmitting(false);
    }
  };

  return (
    <div className="input-block">
      <p>
        Please enter the email supplied to your Wolfestone account manager. If
        you are unsure, please get in touch: sales@wolfestonegroup.co.uk
      </p>
      <form
        className="input-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            className="text-input"
            type="text"
            placeholder="XTRF contact person email"
            value={xtrfEmail}
            onChange={(e) => {
              setXTRFEmail(e.target.value);
              setInvalid(false);
            }}
            style={{
              width: "20rem",
              height: "3rem",
              textAlign: "center",
              fontSize: "20px",
              borderRadius: "18px",
              border: "none",
              alignSelf: "flex-start",
            }}
          />
        </div>
        <button
          className="login-button btn shopify-submit"
          type="submit"
          style={{
            width: "20rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "none",
            marginTop: "1rem",
            alignSelf: "flex-start",
          }}
          disabled={submitting} // Disable the button during form submission
        >
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      {invalid && (
        <p style={{ color: "red" }}>
          Invalid XTRF contact person. Make sure that you have entered the
          correct email.
        </p>
      )}
    </div>
  );
}

export default XTRFEmail;
