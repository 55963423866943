import React from "react";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./components/App";

const msalConfig = {
  auth: {
    clientId: "54dbe91b-51c4-4734-8b4e-bdf45d5e120f",
    authority:
      "https://login.microsoftonline.com/b2bbaf6a-d338-4b28-9efb-4e98507ea975",
    redirectUri: "/",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

createRoot(document.getElementById("root")).render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);
