import React, { useState } from "react";

const FileSelector = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionToggle = (option) => {
    const isSelected = selectedOptions.includes(option);
    let updatedOptions = [];

    if (isSelected) {
      updatedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption !== option
      );
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    props.onChange(props.API_name, updatedOptions);
  };

  const selectAllOptions = () => {
    setSelectedOptions(props.options);
    props.onChange(props.API_name, props.options);
  };

  return (
    <div
      style={{
        borderRadius: "6px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "30px",
        width: "20rem",
      }}
    >
      <h3 style={{ alignSelf: "center" }}>{props.name}</h3>
      <div
        style={{
          backgroundColor: "#fff",
          marginTop: "10px",
          marginBottom: "10px",
          width: "100%",
          borderRadius: "18px",
        }}
      >
        <ul style={{ listStyle: "none", padding: 0 }}>
          {props.options.map((option) => (
            <li key={option} style={{ textAlign: "left", marginBottom: "4px" }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionToggle(option)}
                />
                {option}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={selectAllOptions}
        style={{
          backgroundColor: "#405cf5",
          color: "white",
          marginTop: "10px",
          marginBottom: "10px",
          padding: "5px 10px",
          borderRadius: "18px",
          cursor: "pointer",
          border: "none",
        }}
      >
        Select All
      </button>
    </div>
  );
};

export default FileSelector;
