import React from "react";

function Banner() {
  return (
    <div className="banner-container">
      <a href="https://wolfestone.co.uk/">
        <img
          src="../wolfestone_group_logo-ok.svg"
          className="banner"
          alt="Wolfestone Group Logo"
        />
      </a>
    </div>
  );
}

export default Banner;
