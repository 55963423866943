import React from "react";
import { languages } from "../../languages";
import ImageButton from "./ImageButton";
import axios from "axios";
import DOMAIN from "../../domain";

function Quote(props) {
  const [deleted, setDeleted] = React.useState(false);

  const handleDelete = () => {
    axios
      .delete(`${DOMAIN}/database/quotes/${props.quote.quoteId}`)
      .then(() => {
        // Refresh the page after the delete request is completed
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting quote:", error);
      });
  };

  function idToName(id) {
    const languageKey = Object.keys(languages).find(
      (key) => languages[key] === id
    );
    return languageKey || "";
  }

  const unixTimeToDate = (unixTimeMilliseconds) => {
    const date = new Date(unixTimeMilliseconds);
    const dateString = date.toISOString().split("T")[0];

    return dateString;
  };

  if (!deleted) {
    return (
      <div className="quote" style={{ display: "flex" }}>
        <div>
          <strong>Quote Id</strong>
          <p>{props.quote.idNumber}</p>
        </div>
        <div>
          <strong>Customer Project Number</strong>
          <p>{props.quote.customerProjectNumber}</p>
        </div>
        <div>
          <strong>Project Name</strong>
          <p>{props.quote.name}</p>
        </div>

        <div>
          <strong>Source Language</strong>
          <p>{idToName(props.quote.sourceLanguageId)}</p>
        </div>
        <div>
          <strong>Target Languages</strong>
          {props.quote.targetLanguageIds.map((id, index) => (
            <p key={index}>{idToName(id)}</p>
          ))}
        </div>
        <div>
          <strong>Deadline</strong>
          <p>{unixTimeToDate(props.quote.deliveryDate.time)}</p>
        </div>
        <div>
          <strong>Status</strong>
          <p>{props.quote.done ? "Finished" : "In progress"}</p>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ImageButton
            quoteId={props.quote.quoteId}
            onClick={() => setDeleted(true)}
          />
        </div>
      </div>
    );
  } else if (deleted) {
    return (
      <div
        className="quote"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <div style={{ height: "50px" }}></div>
          <strong>
            Are you sure you want to remove this quote from your dashboard?
          </strong>
          <button
            className="tick-button"
            onClick={() => {
              handleDelete();
            }}
          >
            &#10003;
          </button>
          <button
            className="cross-button"
            onClick={() => {
              setDeleted(false);
            }}
          >
            &#10005;
          </button>
        </div>
      </div>
    );
  }
}

export default Quote;
