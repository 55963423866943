import React from "react";

function BigLogo() {
  return (
    <div className="logoContainer">
      <img
        src="./wolfestone_group_logo-ok.svg"
        alt=""
        style={{ width: "40rem", height: "10rem" }}
      />
    </div>
  );
}

export default BigLogo;
