import { React, useState, useEffect } from "react";
import CreateQuote from "./CreateQuote";
import Quote from "./UI/Quote";
import axios from "axios";
import BigLogo from "./BigLogo";
import { useNavigate } from "react-router-dom";
import DOMAIN from "../domain";
function ClientView() {
  const [home, setHome] = useState(true);

  function toggleView() {
    setHome((prevVal) => {
      return !prevVal;
    });
  }

  function upload(quote) {
    axios.post(`${DOMAIN}/database/quotes/add`, quote);
    console.log("made request to add");
  }

  return (
    <div>
      {home ? (
        <div className="login-container">
          <Home toggleView={toggleView} />
        </div>
      ) : (
        <CreateQuote toggleView={toggleView} save={upload} />
      )}
    </div>
  );
}

function Home(props) {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Shopify Connector - Projects";
  }, []);

  useEffect(() => {
    axios
      .get(`${DOMAIN}/database/quotes/${localStorage.getItem("shopHostname")}`)
      .then((res) => {
        setQuotes(res.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <h1 style={{ alignSelf: "center", marginTop: "100px" }}>Loading...</h1>
    );
  }

  if (quotes.length > 0) {
    return (
      <div className="homeDiv">
        <div style={{ marginBottom: "0" }}>
          <BigLogo />
        </div>
        <button
          className="login-button btn shopify-submit"
          onClick={() => {
            localStorage.setItem("shopify-authenticated", false);
            navigate("/");
          }}
          style={{
            alignSelf: "flex-end",
            marginTop: "0",
            width: "10rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "none",
          }}
        >
          Logout
        </button>
        <h1 style={{ alignSelf: "flex-start" }}>Requests</h1>
        <button
          className="login-button btn shopify-submit"
          onClick={props.toggleView}
          style={{ alignSelf: "flex-start" }}
        >
          New Quote
        </button>
        <div>
          {quotes.length > 0 &&
            quotes.map((quote, index) => {
              return <Quote key={index} quote={quote} />;
            })}
        </div>
        <div className="filler" style={{ height: "600px" }}></div>
      </div>
    );
  } else {
    return (
      <div className="homeDiv">
        <div style={{ marginBottom: "0" }}>
          <BigLogo />
        </div>
        <button
          className="login-button btn shopify-submit"
          onClick={() => {
            localStorage.setItem("shopify-authenticated", false);
            navigate("/");
          }}
          style={{
            alignSelf: "flex-end",
            marginTop: "0",
            width: "10rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "none",
          }}
        >
          Logout
        </button>
        <p
          style={{ textAlign: "center", fontSize: "20px", marginTop: "100px" }}
        >
          It is a little empty here at the moment. <br></br> Press the New Quote
          button below to start building <br></br> a quote for translation of
          your Shopify store.
        </p>
        <button
          className="login-button btn shopify-submit"
          onClick={props.toggleView}
          style={{
            alignSelf: "center",
            width: "20rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "none",
          }}
        >
          New Quote
        </button>
        <div className="filler" style={{ height: "700px" }}></div>
      </div>
    );
  }
}

export default ClientView;
