import React from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import LoginScreen from "./LoginScreen";
import Wolfestone from "./Wolfestone";
import BigLogo from "./BigLogo";

function WolfestoneMain() {
  return (
    <div className="app">
      <UnauthenticatedTemplate>
        <div className="login-container">
          <BigLogo />
          <div className="login-section">
            <LoginScreen />
          </div>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Wolfestone />
      </AuthenticatedTemplate>
      <div className="filler" style={{ height: "500px" }}></div>
    </div>
  );
}

export default WolfestoneMain;
